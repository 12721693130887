<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                海通证券&非凸科技，探讨数智交易发展及生态合作
              </p>
              <div class="fst-italic mb-2">2023年8月9日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >海通证券</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/53/01-主题.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近日，海通证券数字金融部联合非凸科技在深圳开展了科技赋能投资系列活动，与在场嘉宾共同探讨了数智交易行业的可持续发展与生态伙伴的合作共赢。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/53/02-徐晓啸.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    海通证券积极拥抱数字化，致力于成为具有数字化基因的财富管理机构。会上，海通证券数字金融部机构平台管理部经理徐晓啸详细介绍了机构交易全链路服务，从客户端到算法，再到柜台、行情，最后到定制化。海通证券始终坚持买方服务理念，精心打造的e海方舟一站式智能交易平台，以其极致的交易体验、安全的交易性能，为客户智能化交易赋能，从而助力资本市场运筹帷幄。除此之外，海通证券为满足客户个性化算法需求，搭载了非凸算法在内的主动拆单算法、底仓增强算法，覆盖了股票、基金、债券、回购、港股通、融资融券等业务品种，截至今年7月底，算法交易及底仓增强策略总成交额已超1000亿元。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/53/03-郑媛姿.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    随后，非凸科技CBO郑媛姿从行业状况、策略逻辑、算法产品、多维赋能等角度进行了分享。交易技术的创新，显著地降低了交易成本，算法交易作为一种程序化交易方式，可降低85%的冲击成本。基于大家对更有效的交易执行方式的追求，非凸科技通过更智能的交易算法选择，持续进行策略与技术间的精进，为客户提供更精准、更便捷、更切实的交易体验和交易价值。当前，非凸AI算法能力已得到百家机构客户的认可，在行业内也越来越有影响力，期望以更先进的技术和更懂业务的产品，持续为数智交易生态赋能。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/53/04-刘爱华.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    接下来，海通证券证券金融部副总经理刘爱华为大家介绍了专业机构融资融券服务方案。海通证券融资融券业务一直处于行业领先地位，业务发展的各个创新环节均获得首批试点券商资格。融资融券业务主要为客户提供集交易支持、资金支持、券源支持、策略支持于一体的综合服务体系，满足机构客户、零售客户等各类投资者杠杆融资、多空交易、风险管理等多元化需求。除此之外，该业务具备雄厚的客户基础、丰富的券源储备、完善的风险管理以及专业的系统支持等诸多优势。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/53/05-厉辰浩.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    最后，海通证券金融产品部总经理助理厉辰浩对“通合魔方”产品服务体系进行了详细介绍。“通合魔方”体系涵盖产品、服务、系统及陪伴，主要为客户提供最合适的产品，从而更好地帮助客户在顺境与逆境中实现资产的保值增值。“通合魔方”具备四大优势，一是产品体系完整，对全市场的主流产品进行了全覆盖；二是产品细分专业，建立了基于买方视角的、定量与定性相结合的金融产品研究与配置体系；三是产品定位精准，将细分的产品和客户的需求进行了匹配；四是全生命周期服务，提供投前、投中、投后、线上、线下全方位售后陪伴。
                  </p>
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/53/06-茶歇.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    未来，非凸科技与海通证券将继续发挥各自优势，达成更多层次的深度合作，推动数智交易行业高质量发展。
                  </p>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News53",
};
</script>

<style></style>
